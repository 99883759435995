/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/job/:id",
      name: "job",
      component: () => import("@/views/company/SingleJob.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Job",
        breadcrumb: [
          {
            text: "Job",
            active: true,
          },
        ],
      },
    },
    {
      path: "/onboard",
      name: "onboard",
      component: () => import("@/views/onboard/onboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Onboard",
        breadcrumb: [
          {
            text: "Onboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile/:slug/edit",
      name: "profile-edit",
      component: () => import("@/views/profile/partials/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/change-password",
      name: "change-password",
      component: () => import("@/views/PasswordChange.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Password",
        breadcrumb: [
          {
            text: "Change Password",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/candidatemailverify/:id",
      component: () => import("@/views/verify.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
